<template>
	<div class="system-menu-container">
		<div :class="visible ? 'box-modal-hide' : 'box-modal-show'" style="padding: 15px">
			<el-form :model="goods" size="small" label-width="100px" class="mt15 mb15">
				<el-row>
					<el-col :span="6">
						<el-form-item label="商品名称">
							<el-input v-model="goods.name" placeholder="请输入商品名称" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="商品ID">
							<el-input v-model="goods.goods_id" placeholder="请输入商品ID" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="商品编号">
							<el-input v-model="goods.goods_no" placeholder="请输入商品编号" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="状态">
							<el-select v-model="goods.status" placeholder="请选择状态" clearable class="w100">
								<el-option label="上架" value="0"></el-option>
								<el-option label="下架" value="1"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item align="right">
							<el-button icon="el-icon-refresh">清空</el-button>
							<el-button type="primary" icon="el-icon-search" @click="submitTap()">立即查询</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<el-card shadow="never">
				<div style="padding-bottom: 15px">
					<el-row>
						<el-button size="small" type="danger" icon="el-icon-delete" @click="batchDelTap()">批量删除</el-button>
						<el-button size="small" type="primary" icon="el-icon-edit" @click="checkDetail()">添加商品</el-button>
					</el-row>
				</div>
				<el-table v-loading="loading" :data="tableData" stripe style="width: 100%">
					<el-table-column fixed type="selection" width="55" align="center"></el-table-column>
					<el-table-column fixed width="68" label="商品ID">
						<template #default="scope">
							<span>{{ scope.row.goods_id }}</span>
						</template>
					</el-table-column>
					<el-table-column fixed label="商品名称" width="180">
						<template #default="scope">
							<span>{{ scope.row.goods_name }}</span>
						</template>
					</el-table-column>
					<el-table-column fixed label="商品封面">
						<template #default="scope">
							<el-avatar shape="square" :size="50" :src="scope.row.goods_thumb"></el-avatar>
						</template>
					</el-table-column>
					<el-table-column fixed label="状态">
						<template #default="scope">
							<span v-if="scope.row.status == 1" class="color-primary dot">上架</span>
							<span v-else class="color-danger">下架</span>
						</template>
					</el-table-column>
					<el-table-column fixed label="商品价格">
						<template #default="scope">
							<span>{{ scope.row.goods_price }}</span>
						</template>
					</el-table-column>
					<el-table-column label="划线价">
						<template #default="scope">
							<span>{{ scope.row.line_price }}</span>
						</template>
					</el-table-column>
					<el-table-column label="销量">
						<template #default="scope">
							<span>{{ scope.row.goods_sales }}</span>
						</template>
					</el-table-column>
					<el-table-column label="库存">
						<template #default="scope">
							<span>{{ scope.row.stock_num }}</span>
						</template>
					</el-table-column>
					<el-table-column label="创建日期" width="160" sortable fixed="right">
						<template #default="scope">
							<span>{{ scope.row.created_at }}</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="105" fixed="right">
						<template #default="scope">
							<el-button size="mini" type="text" @click="checkDetail(scope.row)">编辑</el-button>
							<el-button size="mini" class="color-danger" type="text" @click="deleteTap(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="block" style="margin-top: 15px">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="pageInfo.now"
						:page-sizes="[10, 20, 50, 100, 200]"
						:page-size="pageInfo.size"
						layout="total, sizes, prev, pager, next, jumper"
						:total="pageInfo.count"
					>
					</el-pagination>
				</div>
			</el-card>
		</div>
		<detail v-if="visible" :data="rowData" @closeTap="closeTap"></detail>
	</div>
</template>

<script>
import { goods_index, goods_del } from '@/api/goods';
import detail from './component/detail.vue';
export default {
	components: { detail },
	name: 'goods',
	data() {
		return {
			visible: false,
			goods: {},
			pageInfo: {
				now: 1,
				size: 20,
			},
			tableData: [],
			loading: true,
		};
	},
	created() {
		this.initData();
	},
	methods: {
		initData() {
			var param = {
				status: this.goods.status || undefined,
				pageSize: this.pageInfo.size || undefined,
				pageNow: this.pageInfo.now || undefined,
			};

			goods_index({ ...param })
				.then((res) => {
					if (res.statusCode !== 200) {
						this.$message({
							type: 'error',
							message: res.message,
						});
						return true;
					}
					this.pageInfo.now = res.page.now;
					this.pageInfo.size = res.page.size;
					this.tableData = res.lists;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		closeTap() {
			this.visible = false;
			this.initData();
		},
		checkDetail(data) {
			this.rowData = data;
			this.visible = true;
		},
		submitTap() {
			this.loading = true;
			this.initData();
		},
		batchDelTap() {
			this.loading = true;
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				this.loading = false;
			});
		},
		deleteTap(e) {
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				this.loading = true;
				goods_del(e.goods_id)
					.then((res) => {
						if (res.statusCode == 200) {
							this.$message({
								type: 'success',
								message: res.message,
							});
							this.initData();
						}
					})
					.finally(() => {
						this.loading = false;
					});
			});
		},
		handleSizeChange(val) {
			this.loading = true;
			this.pageInfo.size = val;
			this.initData();
		},
		handleCurrentChange(val) {
			this.loading = true;
			this.pageInfo.now = val;
			this.initData();
		},
	},
};
</script>
