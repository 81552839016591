<template>
	<div>
		<div style="margin: 12px 12px 58px 12px">
			<el-tabs v-model="activeName" type="card" size="mini">
				<el-tab-pane label="盲盒信息" name="info">
					<div style="padding: 15px">
						<el-form :model="blind_box" label-width="90px">
							<el-row :gutter="20">
								<el-col :span="24">
									<el-form-item label="盲盒名称">
										<el-input
											v-model="blind_box.title"
											placeholder="请输入盲盒名称"
											clearable
											maxlength="35"
											show-word-limit
											style="width: 380px"
										></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item label="状态">
										<el-radio-group v-model="blind_box.status" size="mini">
											<el-radio :label="1">上架</el-radio>
											<el-radio :label="0">下架</el-radio>
										</el-radio-group>
									</el-form-item>
								</el-col>

								<el-col :span="24">
									<el-form-item label="排序">
										<el-input v-model="blind_box.sort" style="width: 380px"></el-input>
									</el-form-item>
								</el-col>

								<!-- <el-col :span="24">
									<el-form-item label="会员成长值">
										<el-input v-model="blind_box.gift_growth" style="width: 380px"></el-input>
									</el-form-item>
								</el-col> -->

								<el-col :span="24">
									<el-form-item label="盲盒价格">
										<el-input v-model="blind_box.price" placeholder="请输入盲盒价格" clearable style="width: 380px"></el-input>
									</el-form-item>
								</el-col>

								<el-col :span="24">
									<el-form-item label="主图">
										<div style="float: left">
											<div style="margin: 6px 6px; float: left; overflow: hidden; width: 120px" v-for="(item, index) in blind_box.thumb" :key="index">
												<div
													class="material-img"
													v-on:mouseenter="visibleEven(blind_box.thumb, item)"
													@mouseleave="visibleEven(blind_box.thumb, item)"
												>
													<img class="images" :src="item.url" />
													<div class="item-selected" v-if="item.selected" @click="removeTap(blind_box.thumb, index)">
														<i class="el-icon-delete" />
													</div>
												</div>
											</div>
										</div>
										<div class="material-upload" style="margin: 6px 6px">
											<span @click="showMaterialTap()">添加图片</span>
										</div>
										<span class="sub-title"><i style="padding-right: 5px; color: red">*</i>建议尺寸：800*800</span>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item label="盲盒详情" prop="md_content">
										<mavon-editor
											ref="mavonEditor"
											v-model="blind_box.md_content"
											:toolbars="markdown.toolbars"
											:subfield="false"
											:ishljs="true"
											:boxShadow="true"
											:externalLink="true"
											fontSize="14px"
											previewBackground="#fff"
											defaultOpen="edit"
											placeholder="请编辑你的盲盒详情 ..."
											class="editor full-height"
											@save="editorSave"
											@change="editorChange"
											@imgAdd="editorUploadImage"
										>
										</mavon-editor>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item label="盲盒规则" prop="md_rule">
										<mavon-editor
											ref="ruleEditor"
											v-model="blind_box.md_rule"
											:toolbars="markdown.toolbars"
											:subfield="false"
											:ishljs="true"
											:boxShadow="true"
											:externalLink="true"
											fontSize="14px"
											previewBackground="#fff"
											defaultOpen="edit"
											placeholder="请编辑你的盲盒规则 ..."
											class="editor full-height"
											@change="editorRuleChange"
											@imgAdd="editorRuleUploadImage"
										>
										</mavon-editor>
									</el-form-item>
								</el-col>
							</el-row>
						</el-form>
						<!-- <el-divider content-position="left">Goods 数据</el-divider>
						<pre><code>{{ blind_box }}</code></pre> -->
					</div>
				</el-tab-pane>
				<el-tab-pane label="盲盒商品" name="spec">
					<div>
						<el-button size="small" type="primary" icon="el-icon-edit" @click="selectGroup()">选择概率组</el-button>
						<el-table v-loading="loading" :data="[group]" stripe style="width: 100%">
							<el-table-column label="名称">
								<template #default="scope">
									<span>{{ scope.row.name }}</span>
								</template>
							</el-table-column>
							<el-table-column label="摘要">
								<template #default="scope">
									<span>{{ scope.row.detail }}</span>
								</template>
							</el-table-column>
							<el-table-column label="描述">
								<template #default="scope">
									<span>{{ scope.row.remark }}</span>
								</template>
							</el-table-column>
						</el-table>
					</div>

					<div style="margin-top: 40px">
						<el-button size="small" type="primary" icon="el-icon-edit" @click="addGoodsTap()">添加商品</el-button>
						<el-table v-loading="loading" :data="productData" stripe style="width: 100%">
							<el-table-column label="商品名称">
								<template #default="scope">
									<span>{{ scope.row.goods_name }}</span>
								</template>
							</el-table-column>
							<el-table-column label="商品简称">
								<template #default="scope">
									<el-input v-model="scope.row.title" placeholder="请输入商品简称" clearable maxlength="10" show-word-limit></el-input>
								</template>
							</el-table-column>
							<el-table-column label="封面">
								<template #default="scope">
									<el-avatar shape="square" :size="50" :src="scope.row.goods_thumb"></el-avatar>
								</template>
							</el-table-column>
							<el-table-column label="商品价格">
								<template #default="scope">
									<span>{{ scope.row.goods_price }}</span>
								</template>
							</el-table-column>
							<el-table-column label="转成积分数值">
								<template #default="scope">
									<el-input-number
										v-model="scope.row.equivalent_point"
										placeholder="请输入积分数值"
										controls-position="right"
										:min="0"
									></el-input-number>
								</template>
							</el-table-column>

							<el-table-column label="盲盒类型">
								<template #default="scope">
									<el-select v-model="scope.row.deal_id" placeholder="请选择">
										<el-option v-for="item in type_lists" :key="item.id" :label="item.title" :value="item.id"> </el-option>
									</el-select>
								</template>
							</el-table-column>
							<el-table-column label="操作" width="165" fixed="right">
								<template #default="scope">
									<el-button size="mini" type="text" @click="secondPool(scope.row)" v-if="scope.row.id">二联</el-button>
									<el-button size="mini" type="text" @click="removeTap(scope.row)">移除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>

		<div class="bottom-fixed" style="z-index: 99">
			<div class="box-item">
				<el-button size="medium" @click="closeTap()">取 消</el-button>
				<el-button size="medium" type="primary" @click="submitTap()">立即提交</el-button>
			</div>
		</div>

		<goods v-if="is_show" :data="blind_box.goods_ids" @multiplesIds="multiplesIds" @closeTap="layerCloseTap"></goods>
		<dealGroups v-if="is_group_show" :data="group_id" @selected="groupSelected" @closeTap="groupCloseTap"></dealGroups>
		<secondPool v-if="is_pool_show" :data="goods_info" @closeTap="poolCloseTap"></secondPool>

		<material
			v-if="is_material"
			:selected="selected"
			:type="material_type"
			:limit="limit"
			@materialTap="materialTap"
			@closeTap="showMaterialTap"
		></material>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { goods_list } from '@/api/goods';
import goods from '@/components/dialog/goods.vue';
import dealGroups from '@/components/dialog/deal-groups.vue';
import material from '@/components/dialog/material.vue';
import secondPool from './secondPool.vue';
import { blind_box_get, blind_box_post, box_deal_list, box_deal_group } from '@/api/blind_box';
import { file_upload } from '@/api/common';
import { mavonEditor } from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
import { Session } from '@/utils/storage';
import { material_post } from '@/api/material';

export default {
	name: 'detail',
	components: {
		goods,
		dealGroups,
		material,
		secondPool,
		mavonEditor,
	},
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			is_material: false,
			productData: [],
			group: {},
			dir_id: 0,
			group_id: 0,
			goods_info: {},
			selected: [],
			material_type: 'image',
			limit: 1,
			type_lists: [],
			upload_url: process.env.VUE_APP_BASE_API + '/base/upload',
			headers: {
				Authorization: `Bearer ${Session.get('token')}`,
			},
			is_show: false,
			is_group_show: false,
			is_pool_show: false,
			loading: false,
			tableData: [],
			couponData: [],
			// 编辑器相关信息
			markdown: {
				toolbars: {
					bold: true, // 粗体
					italic: true, // 斜体
					header: true, // 标题
					underline: true, // 下划线
					strikethrough: true, // 中划线
					mark: true, // 标记
					ol: true, // 有序列表
					ul: true, // 无序列表
					link: true, // 链接
					imagelink: true, // 图片链接
					code: true, // code
					table: true, // 表格
					alignleft: true, // 左对齐
					aligncenter: true, // 居中
					alignright: true, // 右对齐
					subfield: true,
					htmlcode: true,
				},
			},
			activeName: 'info',
			new_attribute: '',
			select: false,
			maxLimit: 1,
			blind_box: {
				sku: [],
				attribute: [],
				thumb: [],
			},
			dialogImageUrl: '',
			freight: [],
			dialogVisible: false,
			sourceAttribute: [],
			structure: [
				{
					name: 'goods_name',
					type: 'slot',
					label: '商品名称',
				},
				{
					name: 'goods_thumb',
					type: 'slot',
					label: '商品封面',
				},
				{
					name: 'rule_type',
					type: 'slot',
					label: '类型',
					required: true,
				},
				{
					name: 'stock_num',
					type: 'input',
					label: '商品数量',
					required: true,
				},
			],
		};
	},
	created() {
		this.initData();
	},
	mounted() {},
	methods: {
		initData() {
			if (Object.keys(this.data).length > 0) {
				this.loading = true;
				blind_box_get(this.data.id)
					.then((res) => {
						this.blind_box = res.data;
						this.productData = res.data.product;
						return res.data.deal_group_id;
					})
					.then((gid) => {
						return box_deal_group(gid).then((res) => {
							this.group = res.data;
							return gid;
						});
					})
					.then((gid) => {
						var param = {
							group_id: gid,
						};
						box_deal_list({ ...param }).then((res) => {
							if (res.statusCode !== 200) {
								this.$message({
									type: 'error',
									message: res.message,
								});
								return false;
							}
							this.type_lists = res.data;
						});
					})
					.then(() => {
						goods_list().then((res) => {
							this.goods = res.data;
						});
					})
					.finally(() => {
						this.loading = false;
					});
			}
		},
		addGoodsTap() {
			this.is_show = true;
		},
		selectGroup() {
			this.is_group_show = true;
		},
		showMaterialTap() {
			this.is_material = !this.is_material;
		},
		removeTap(data, index) {
			if (index) {
				data.splice(index, 1);
			} else {
				this.productData = this.productData.filter((v) => v.goods_id != data.goods_id);
			}
		},
		secondPool(data) {
			this.is_pool_show = true;
			this.goods_info = data;
		},

		visibleEven(data, row) {
			let index = data.findIndex((item) => item.name === row.name);
			if (index !== -1) {
				data[index]['selected'] = !data[index]['selected'];
			}
			this.$set(data, index, data[index]);
		},
		materialTap(data) {
			this.blind_box.thumb = data;
			this.is_material = false;
		},
		// 编辑器保存回调事件
		editorSave() {
			this.triggerSave();
		},

		// 编辑器修改回调事件
		editorChange(value, render) {
			this.blind_box.content = render;
			this.blind_box.md_content = value;
		},
		editorRuleChange(value, render) {
			this.blind_box.rule = render;
			this.blind_box.md_rule = value;
		},
		upload(e) {
			if (e.target.files.length == 0) {
				return false;
			}
			let files = e.target.files;
			let formData = new FormData();
			files.forEach((file) => {
				formData.append('file[]', file);
			});
			formData.append('dir_id', this.dir_id);
			material_post(formData)
				.then((res) => {
					if (res.statusCode != 200) {
						this.$message({
							type: 'error',
							message: res.message,
						});
						return false;
					}
					res.data.forEach((file, index) => {
						this.$refs.mavonEditor.$img2Url(index, file.path);
					});
				})
				.catch(() => {
					this.$message({
						type: 'error',
						message: '上传失败',
					});
				});
		},
		// 编辑器上传图片回调事件
		editorUploadImage(pos, file) {
			let formdata = new FormData();
			formdata.append('file', file);

			let save_path = '';
			file_upload(formdata)
				.then((res) => {
					if (res.statusCode != 200) return false;
					this.$refs.mavonEditor.$img2Url(pos, res.data.file_url);
					save_path = res.data.file_url || '';
				})
				.catch(() => {
					this.$refs.mavonEditor.$img2Url(pos, save_path);
				});
		},
		editorRuleUploadImage(pos, file) {
			let formdata = new FormData();
			formdata.append('file', file);

			let save_path = '';
			file_upload(formdata)
				.then((res) => {
					if (res.statusCode != 200) return false;
					this.$refs.ruleEditor.$img2Url(pos, res.data.file_url);
					save_path = res.data.file_url || '';
				})
				.catch(() => {
					this.$refs.ruleEditor.$img2Url(pos, save_path);
				});
		},
		removeThumbEven(file, fileList) {
			this.blind_box.thumb = [];
			var thumbs = [];
			fileList.forEach(function (item) {
				thumbs.push({ name: item.name, url: item.url });
			});
			this.blind_box.thumb = thumbs;
		},
		beforeUploadThumb(res) {
			var obj = {
				url: res.data.file_url,
				name: res.data.file_name,
			};
			this.blind_box.thumb.push(JSON.parse(JSON.stringify(obj)));
		},
		beforeUpload(file) {
			const ext = ['jpg', 'png', 'gif', 'bmp'];
			const size = 2;
			const fileName = file.name.split('.');
			const fileExt = fileName[fileName.length - 1];
			const isTypeOk = ext.indexOf(fileExt) >= 0;
			const isSizeOk = file.size / 1024 / 1024 < size;
			if (!isTypeOk) {
				this.$message.error(`上传图片只支持 ${ext.join(' / ')} 格式！`);
			}
			if (!isSizeOk) {
				this.$message.error(`上传图片大小不能超过 ${size}MB！`);
			}
			return isTypeOk && isSizeOk;
		},
		// 图片上传
		imageUpload(res, data) {
			data.row.image = res.data.file_url;
			this.$message.success('图片上传成功');
			this.$refs.skuForm.validateFieldByRows(data.$index, 'image', () => {});
		},
		imageRemove(data) {
			data.row.image = '';
		},
		closeTap() {
			this.$emit('closeTap');
		},
		submitTap() {
			this.blind_box.product = this.productData;
			this.blind_box.deal_group_id = this.group.id;
			blind_box_post(this.blind_box)
				.then((res) => {
					if (res.statusCode == 200) {
						Message.success(res.message);
						this.$emit('closeTap');
					} else {
						Message.error(res.message);
					}
				})
				.catch(() => {});
		},
		layerCloseTap() {
			this.is_show = false;
		},
		groupCloseTap() {
			this.is_group_show = false;
		},
		poolCloseTap() {
			this.is_pool_show = false;
		},
		multiplesIds(ids) {
			var param = {
				goods_ids: ids,
			};
			goods_list({ ...param })
				.then((res) => {
					if (res.statusCode == 200) {
						res.data.forEach((item) => {
							this.productData.push({
								goods_id: item.goods_id,
								goods_price: item.goods_price,
								goods_name: item.goods_name,
								goods_thumb: item.goods_thumb,
							});
						});
						this.is_show = false;
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		groupSelected(e) {
			this.group = e;

			this.loading = true;
			var param = {
				group_id: e.id,
			};
			box_deal_list({ ...param })
				.then((res) => {
					if (res.statusCode !== 200) {
						this.$message({
							type: 'error',
							message: res.message,
						});
						return true;
					}
					this.type_lists = res.data;
					this.is_group_show = false;
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style lang="less" scoped>
/deep/ .el-upload-dragger {
	width: initial;
	height: initial;
	border: 0;
	border-radius: 0;
	background-color: initial;
	overflow: auto;
}
/deep/ .image-upload-container {
	.el-image {
		vertical-align: middle;
		margin: 0 5px;
		width: 30px;
		height: 30px;
	}
	.images-upload,
	> .el-button {
		display: inline-block;
		margin: 0 5px;
		vertical-align: middle;
	}
}
</style>
