import request from '@/utils/request';

export function blind_box_list(params) {
    return request({
        url: '/blind_box',
        method: 'get',
        params: params,
    });
}

export function blind_box_post(params) {
    return request({
        url: '/blind_box',
        method: 'post',
        data: params,
    });
}

export function blind_box_get(id, params) {
    return request({
        url: '/blind_box/' + id,
        method: 'get',
        params: params,
    });
}

export function blind_box_del(id, params = '') {
    return request({
        url: '/blind_box/' + id,
        method: 'delete',
        params: params,
    });
}

export function blind_box_put(params) {
    return request({
        url: '/blind_box',
        method: 'put',
        params: params,
    });
}

export function box_deal_list(params) {
    return request({
        url: '/blind_box/deal/lists',
        method: 'get',
        params: params,
    });
}

export function box_deal_post(params) {
    return request({
        url: '/blind_box/deal',
        method: 'post',
        data: params,
    });
}

export function box_deal_del(id, params = '') {
    return request({
        url: '/blind_box/deal/' + id,
        method: 'delete',
        data: params,
    });
}

export function box_deal_group_list(params) {
    return request({
        url: '/blind_box/deal_group',
        method: 'get',
        params: params,
    });
}

export function box_deal_group(id, params) {
    return request({
        url: '/blind_box/deal_group/' + id,
        method: 'get',
        params: params,
    });
}

export function box_deal_group_post(params) {
    return request({
        url: '/blind_box/deal_group',
        method: 'post',
        data: params,
    });
}

export function box_deal_group_del(id, params = '') {
    return request({
        url: '/blind_box/deal_group/' + id,
        method: 'delete',
        data: params,
    });
}

export function second_pool_list(params) {
    return request({
        url: '/blind_box/second_pool/lists',
        method: 'get',
        params: params,
    });
}

export function second_pool_post(params) {
    return request({
        url: '/blind_box/second_pool',
        method: 'post',
        data: params,
    });
}
export function second_pool_del(id, params = '') { 
    return request({
        url: '/blind_box/second_pool/' + id,
        method: 'delete',
        data: params,
    });
}
