import request from '@/utils/request';

// 用户列表
export function user_list(params) {
    return request({
        url: '/user/lists',
        method: 'get',
        params: params,
    });
}

export function user_index(params) {
    return request({
        url: '/user',
        method: 'get',
        params: params,
    });
}

export function user_get(id, params) {
    return request({
        url: '/user/' + id,
        method: 'get',
        params: params,
    });
}

export function user_post(params) {
    return request({
        url: '/user',
        method: 'post',
        data: params,
    });
}

export function user_del(id) {
    return request({
        url: '/user/' + id,
        method: 'delete',
    });
}

// 优惠券列表
export function user_coupon(params) {
    return request({
        url: '/user/coupon',
        method: 'get',
        params: params,
    });
}

// 积分列表
export function user_point(params) {
    return request({
        url: '/user/point',
        method: 'get',
        params: params,
    });
}

// 会员列表
export function member_list(params) {
    return request({
        url: '/member/level/lists',
        method: 'get',
        params: params,
    });
}

export function member_index(params) {
    return request({
        url: '/member/level',
        method: 'get',
        params: params,
    });
}

export function member_get(id, params) {
    return request({
        url: '/member/level/' + id,
        method: 'get',
        params: params,
    });
}

export function member_post(params) {
    return request({
        url: '/member/level',
        method: 'post',
        data: params,
    });
}

export function member_del(id) {
    return request({
        url: '/member/level/' + id,
        method: 'delete',
    });
}

// 权益列表
export function useful_list(params) {
    return request({
        url: '/member/useful/lists',
        method: 'get',
        params: params,
    });
}

export function useful_index(params) {
    return request({
        url: '/member/useful',
        method: 'get',
        params: params,
    });
}

export function useful_get(id, params) {
    return request({
        url: '/member/useful/' + id,
        method: 'get',
        params: params,
    });
}

export function useful_post(params) {
    return request({
        url: '/member/useful',
        method: 'post',
        data: params,
    });
}

export function useful_del(id) {
    return request({
        url: '/member/useful/' + id,
        method: 'delete',
    });
}


// 地址列表
export function address_list(params) {
    return request({
        url: '/address/lists',
        method: 'get',
        params: params,
    });
}

export function address_index(params) {
    return request({
        url: '/address',
        method: 'get',
        params: params,
    });
}

export function address_get(id, params) {
    return request({
        url: '/address/' + id,
        method: 'get',
        params: params,
    });
}

export function address_del(id) {
    return request({
        url: '/address/' + id,
        method: 'delete',
    });
}

export function point_index(params) {
    return request({
        url: '/point',
        method: 'get',
        params: params,
    });
}