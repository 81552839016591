<template>
	<div class="lum-dialog-mask">
		<el-container class="lum-dialog-box" :class="{ 'full-screen': fullscreen }">
			<el-header height="60px" class="header">
				<p class="title">
					<span>概率组</span>
				</p>
				<p class="tools">
					<i
						class="iconfont"
						style="transform: scale(0.85)"
						:class="fullscreen ? 'icon-tuichuquanping' : 'icon-quanping'"
						@click="fullscreen = !fullscreen"
					/>
					<i class="el-icon-close" @click="closeEvent()" />
				</p>
			</el-header>
			<el-container class="full-height ov-hidden">
				<div style="width: 100%; padding: 0 15px">
					<el-form :model="searchParams" size="small" label-width="100px" class="mt15">
						<el-row>
							<el-col :span="12">
								<el-form-item label="名称">
									<el-input v-model="searchParams.name" placeholder="请输入名称" clearable></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item>
									<el-button type="primary" icon="el-icon-search" @click="submitTap()">立即查询</el-button>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<el-table ref="selectTable" v-loading="loading" :data="tableData" height="400" style="width: 100%">
						<el-table-column width="40">
							<template slot-scope="scope">
								<el-radio v-model="radioSelect" :label="scope.row.id" @change.native.stop="(e) => handleRadioChange(e, scope.row)">&nbsp;</el-radio>
							</template>
						</el-table-column>
						<el-table-column label="ID">
							<template #default="scope">
								<span>{{ scope.row.id }}</span>
							</template>
						</el-table-column>
						<el-table-column label="名称">
							<template #default="scope">
								<span>{{ scope.row.name }}</span>
							</template>
						</el-table-column>
						<el-table-column label="摘要">
							<template #default="scope">
								<span>{{ scope.row.detail }}</span>
							</template>
						</el-table-column>
						<el-table-column label="描述">
							<template #default="scope">
								<span>{{ scope.row.remark }}</span>
							</template>
						</el-table-column>
					</el-table>

					<div class="block" style="width: 100%; margin-top: 5px">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page="pageInfo.now"
							:page-sizes="[10, 20, 50, 100, 200]"
							:page-size="pageInfo.size"
							layout="total, sizes, prev, pager, next, jumper"
							:total="pageInfo.count"
						>
						</el-pagination>
					</div>
				</div>
			</el-container>
			<el-footer class="footer mt15">
				<div>
					<el-button size="mini" @click="closeEvent()">取消</el-button>
					<el-button size="mini" type="primary" @click="submitTap()">确认提交</el-button>
				</div>
			</el-footer>
		</el-container>
	</div>
</template>

<script>
import { box_deal_group_list } from '@/api/blind_box';
export default {
	name: 'fullscreen',
	props: {
		data: {
			type: Number,
			default: () => null,
		},
	},
	data() {
		return {
			fullscreen: false,
			tableData: [],
			pageInfo: {
				now: 1,
				size: 20,
			},
			searchParams: {},
			loading: false,
			radioSelect: 0,
			group: {},
		};
	},
	mounted() {
		this.radioSelect = this.data;
	},
	created() {
		this.initData();
	},
	methods: {
		handleRadioChange(e, row) {
			e.stopPropagation();
			this.group = Object.assign({}, row);
			console.log(this.group);
		},
		handleClick(tab) {
			this.tableData = [];
			this.pageInfo = {};
			this.scope = tab.name;
			this.initData();
		},
		initData() {
			this.loading = true;
			var param = {
				name: this.searchParams.name || undefined,
				pageSize: this.pageInfo.size || undefined,
				pageNow: this.pageInfo.now || undefined,
			};
			box_deal_group_list({ ...param })
				.then((res) => {
					this.pageInfo = res.page;
					this.tableData = res.lists;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		handleSizeChange(val) {
			this.pageInfo.size = val;
			this.initData();
		},
		handleCurrentChange(val) {
			this.pageInfo.now = val;
			this.initData();
		},
		closeEvent() {
			this.$emit('closeTap');
		},
		submitTap() {
			if (!this.group) {
				this.$message({
					type: 'error',
					message: '不能为空！',
				});
				return;
			}
			this.$emit('selected', this.group);
		},
	},
};
</script>
<style lang="less" scoped>
/deep/.el-scrollbar__wrap {
	overflow-x: hidden;
}

.lum-dialog-mask {
	z-index: 9999;
}

.lum-dialog-box {
	width: 100%;
	height: 640px;
	max-width: 960px;
	transition: 1s ease;
	&.full-screen {
		width: 100%;
		height: 100%;
		max-width: unset;
		margin: 0;
		border-radius: 0px;
	}
	.select_box {
		margin: 15px;
		border: 1px solid #dddd;
		border-radius: 3px;
		height: 100px;
		.el-tag {
			margin: 3px;
		}
	}
}

.el-table--scrollable-y {
	/deep/.el-table__body-wrapper::-webkit-scrollbar {
		width: 4px;
		height: 6px;
	}
	/deep/.gutter {
		width: 4px !important;
	}
}

.el-table--scrollable-y {
	/deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
		background-color: #d8d8d8ff;
		border-radius: 7px;
		// box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
.el-table--scrollable-y {
	/deep/.el-table__body-wrapper::-webkit-scrollbar-track {
		// box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: #fff;
		border-radius: 3px;
	}
}
</style>
