<template>
	<div class="lum-dialog-mask">
		<el-container class="lum-dialog-box" :class="{ 'full-screen': fullscreen }">
			<el-header height="60px" class="header">
				<p class="title">
					<span>商品列表</span>
				</p>
				<p class="tools">
					<i
						class="iconfont"
						style="transform: scale(0.85)"
						:class="fullscreen ? 'icon-tuichuquanping' : 'icon-quanping'"
						@click="fullscreen = !fullscreen"
					/>
					<i class="el-icon-close" @click="closeEvent()" />
				</p>
			</el-header>
			<el-container class="full-height ov-hidden">
				<div style="width: 100%; padding: 0 15px">
					<el-form :model="goods" size="small" label-width="100px" class="mt15">
						<el-row>
							<el-col :span="8">
								<el-form-item label="商品名称">
									<el-input v-model="goods.goods_name" placeholder="请输入商品名称" clearable></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="状态">
									<el-select v-model="goods.status" placeholder="请选择状态" clearable class="w100">
										<el-option label="上架" value="1"></el-option>
										<el-option label="下架" value="2"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item>
									<el-button type="primary" icon="el-icon-search" @click="submitTap()">立即查询</el-button>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<el-table
						ref="multipleTable"
						v-loading="loading"
						:data="tableData"
						height="400"
						style="width: 100%"
					>
						<el-table-column type="selection" width="55"></el-table-column>
						<el-table-column fixed width="68" label="商品ID">
							<template #default="scope">
								<span>{{ scope.row.goods_id }}</span>
							</template>
						</el-table-column>
						<el-table-column fixed label="商品名称" width="180">
							<template #default="scope">
								<span>{{ scope.row.goods_name }}</span>
							</template>
						</el-table-column>
						<el-table-column fixed label="商品封面">
							<template #default="scope">
								<el-avatar shape="square" :size="50" :src="scope.row.goods_thumb"></el-avatar>
							</template>
						</el-table-column>
						<el-table-column fixed label="状态">
							<template #default="scope">
								<span v-if="scope.row.status == 1" class="color-primary dot">上架</span>
								<span v-else class="color-info">下架</span>
							</template>
						</el-table-column>
						<el-table-column fixed label="商品价格">
							<template #default="scope">
								<span>{{ scope.row.goods_price }}</span>
							</template>
						</el-table-column>
						<el-table-column label="销量">
							<template #default="scope">
								<span>{{ scope.row.goods_sales }}</span>
							</template>
						</el-table-column>
						<el-table-column label="库存">
							<template #default="scope">
								<span>{{ scope.row.stock_num }}</span>
							</template>
						</el-table-column>
					</el-table>

					<div class="block" style="width: 100%; margin-top: 5px">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page="pageInfo.now"
							:page-sizes="[10, 20, 50, 100, 200]"
							:page-size="pageInfo.size"
							layout="total, sizes, prev, pager, next, jumper"
							:total="pageInfo.count"
						>
						</el-pagination>
					</div>
				</div>
			</el-container>
			<el-footer class="footer mt15">
				<div>
					<el-button size="mini" @click="closeEvent()">取消</el-button>
					<el-button size="mini" type="primary" @click="submitTap()">确认提交</el-button>
				</div>
			</el-footer>
		</el-container>
	</div>
</template>

<script>
import { goods_index } from '@/api/goods';
export default {
	name: 'fullscreen',
	props: {
		data: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			fullscreen: false,
			tableData: [],
			pageInfo: {
				now: 1,
				size: 20
			},
			goods:{},
			loading: false,
			ids: [],
			multiplesIds: [],
		};
	},
	mounted() {
		if (Object.keys(this.data).length > 0) {
			this.multiplesIds = this.data;
		}
	},
	created() {
		this.initData();
	},
	methods: {
		handleClick(tab) {
			this.tableData = [];
			this.pageInfo = {};
			this.scope = tab.name;
			this.initData();
		},
		initData() {
			this.loading = true;
			var param = {
				goods_name: this.goods.goods_name || undefined,
				status: this.goods.status || undefined,
				pageSize: this.pageInfo.size || undefined,
				pageNow: this.pageInfo.now || undefined,
			};
			goods_index({ ...param })
				.then((res) => {
					this.pageInfo = res.page;
					this.tableData = res.lists;
				})
				.finally(() => {
					// let ids = [];
					// this.tableData.forEach((row) => {
					// 	let index = this.multiplesIds.findIndex((item) => item.id === row.id && item.type === this.scope);
					// 	if (index !== -1) ids.push(row);
					// });
					// ids.forEach((row) => {
					// 	this.$refs.multipleTable.toggleRowSelection(row);
					// });
					this.loading = false;
				});
		},
		handleSizeChange(val) {
			this.pageInfo.size = val;
			this.initData();
		},
		handleCurrentChange(val) {
			this.pageInfo.now = val;
			this.initData();
		},
		closeEvent() {
			this.$emit('closeTap');
		},
		submitTap() {
			if (this.$refs.multipleTable.selection.length <= 0) {
				this.$message({
					type: 'error',
					message: '不能为空！',
				});
				return;
			}
			this.$refs.multipleTable.selection.map((row) => {
				this.ids += ',' + row.goods_id;
			});
			this.$emit('multiplesIds', this.ids);
		},
	},
};
</script>
<style lang="less" scoped>
/deep/.el-scrollbar__wrap {
	overflow-x: hidden;
}

.lum-dialog-mask {
	z-index: 9999;
}

.lum-dialog-box {
	width: 100%;
	height: 640px;
	max-width: 960px;
	transition: 1s ease;
	&.full-screen {
		width: 100%;
		height: 100%;
		max-width: unset;
		margin: 0;
		border-radius: 0px;
	}
	.select_box {
		margin: 15px;
		border: 1px solid #dddd;
		border-radius: 3px;
		height: 100px;
		.el-tag {
			margin: 3px;
		}
	}
}

.el-table--scrollable-y {
  /deep/.el-table__body-wrapper::-webkit-scrollbar {
    width: 4px;
	height: 6px;
  }
  /deep/.gutter {
    width: 4px !important;
  }
}

.el-table--scrollable-y {
  /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: #d8d8d8ff;
    border-radius: 7px;
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
.el-table--scrollable-y {
  /deep/.el-table__body-wrapper::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    border-radius: 3px;
  }
}

</style>